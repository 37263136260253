<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <!--    Subscribe Dialog-->
    <v-dialog
      v-model="subscribeDialog"
      max-width="500px"
    >
      <v-card v-if="!editedItem.subscription">
        <v-toolbar
          flat
        >
          <v-toolbar-title class="grey--text text--darken-2">
            Subscribe to Package
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="closeSubDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container
          fluid
          class="px-4"
        >
          <v-row align="center">
            <v-col cols="12">
              <v-select
                v-model="sub.package"
                outlined
                :hint="`${sub.package.price?sub.package.price+' GHS - ':''}
                 ${sub.package.duration? sub.package.duration+' Months':''}`"
                :items="getPackages"
                item-text="name"
                item-value="_id"
                label="Select Package"
                persistent-hint
                return-object
                single-line
              />
              <v-text-field
                v-model="sub.receiptNo"
                class="mt-1"
                type="number"
                placeholder="Receipt Number"
                outlined
                label="Receipt Number"
              />
              <v-select
                v-model="sub.paymentMethod"
                outlined
                :items="getPaymentMethods"
                label="Payment Method"
                persistent-hint
                single-line
              />
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer />

          <v-btn
            color="grey darken-1"
            text
            @click="closeSubDialog"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            @click="onSubscribe"
          >
            <v-icon left>
              mdi-creation
            </v-icon>
            Subscribe
          </v-btn>
        </v-card-actions>
      </v-card>
      <!--      if Subscription exists for client-->
      <v-card v-else>
        <v-toolbar
          flat
        >
          <v-toolbar-title class="grey--text text--darken-2">
            <v-icon
              class="pulse green mx-4"
              small
            >
              mdi-checkbox-blank-circle
            </v-icon>
            Subscription Active
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="closeSubDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container
          fluid
          class="px-4"
        >
          <v-row align="center">
            <v-col cols="12">
              <v-card-text class="text-center">
                <h4 class="text-h3 font-weight-light mb-0 black--text">
                  {{ editedItem.subscription?editedItem.subscription.package.name:'' }}
                </h4>
                <h6 class="text-h4 mb-6 grey--text">
                  <v-icon
                    left
                    color="#cfb53b"
                  >
                    mdi-star-outline
                  </v-icon>
                  <i class="text-h5 green--text text--darken-1">
                    ${{ editedItem.subscription?editedItem.subscription.package.price:'' }}</i>
                </h6>
                <v-list two-line>
                  <v-list-item-group
                    multiple
                  >
                    <template>
                      <v-list-item>
                        <template>
                          <v-list-item-content>
                            <v-list-item-title>
                              Period Left: <b class="my-2">{{ editedItem.subscription?editedItem.subscription.periodLeft:'' }}</b>
                            </v-list-item-title>

                            <v-list-item-subtitle
                              class="text--primary mt-2"
                            >
                              End Date: <b class="my-2">{{ moment( editedItem.subscription?editedItem.subscription.endDate:'').format('MMM Do YYYY, h:mm a') }}</b>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="closeSubDialog"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--    End Sub Dialog-->
    <v-dialog
      v-model="dialog"
      max-width="800px"
    >
      <v-card
        class="mx-auto"
      >
        <v-toolbar flat>
          <v-toolbar-title class="grey--text">
            <span class="text-h3">{{ formTitle }}</span>
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="close"
          >
            <v-icon class="red--text">
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <validation-observer
            ref="observer"
          >
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="First Name"
                    rules="required"
                  >
                    <v-text-field
                      v-model="editedItem.firstName"
                      placeholder="Last Name"
                      prepend-inner-icon="mdi-account-box"
                      outlined
                      :error-messages="errors"
                      label="First Name"
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Last Name"
                    rules="required"
                  >
                    <v-text-field
                      v-model="editedItem.lastName"
                      placeholder="Last Name"
                      :error-messages="errors"
                      prepend-inner-icon="mdi-account-outline"
                      outlined
                      label="Last Name"
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <v-text-field
                      v-model="editedItem.email"
                      placeholder="Email"
                      prepend-inner-icon="mdi-at"
                      :error-messages="errors"
                      outlined
                      label="Email"
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Phone Number"
                    :rules="{
                      required: true,
                    }"
                  >
                    <vue-tel-input-vuetify
                      v-model="editedItem.phone"
                      placeholder="Phone Number"
                      return-masked-value
                      mask="##########"
                      :error-messages="errors"
                      prepend-inner-icon="mdi-phone"
                      outlined
                      label="Phone Number"
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Address"
                    rules="required"
                  >
                    <v-text-field
                      v-model="editedItem.address"
                      prepend-inner-icon="mdi-map-marker"
                      placeholder="Address"
                      :error-messages="errors"
                      outlined
                      label="Address"
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Date of Birth"
                        rules="required"
                      >
                        <v-text-field
                          v-model="editedItem.dob"
                          label="DOB"
                          :error-messages="errors"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        />
                      </validation-provider>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="editedItem.dob"
                      :max="new Date().toISOString().substr(0, 10)"
                      min="1950-01-01"
                      @change="saveDate"
                    />
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Emergency Contact Name"
                    rules="required"
                  >
                    <v-text-field
                      v-model="editedItem.emergencyContactName"
                      placeholder="Emergency Contact Name"
                      prepend-inner-icon="mdi-shield-account-outline"
                      outlined
                      :error-messages="errors"
                      label="Emergency Contact Name"
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Emergency Contact Phone"
                    rules="required"
                  >
                    <vue-tel-input-vuetify
                      v-model="editedItem.emergencyContactPhone"
                      :error-messages="errors"
                      outlined
                      label="Emergency Contact Phone"
                      placeholder="Emergency Contact Phone"
                      prepend-inner-icon="mdi-phone-outline"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-container>
          </validation-observer>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="grey darken-1"
            text
            @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            @click="onAddClient"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="text-h5">
          Are you sure you want to delete this client?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="closeDelete"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="deleteItemConfirm"
          >
            OK
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="fetchup">
      <v-skeleton-loader
        type="table-heading, list-item-two-line, image, table-tfoot"
      />
    </div>
    <base-material-card
      v-else
      color="black"
    >
      <template v-slot:heading>
        <div class="text-h3 font-weight-light">
          <v-row class="align-content-md-space-between">
            <v-col
              cols="12"
              md="9"
              sm="6"
            >
              <div class="text-h3 font-weight-light">
                Manage Clients <i class="text-caption">Total: <b>{{ total }}</b></i>
              </div>

              <div class="text-subtitle-1 font-weight-light">
                Please manage all client activities from this page
              </div>
            </v-col>
            <v-col
              md="3"
              sm="6"
              style="text-align:right"
            >
              <v-btn
                outlined
                color="primary"
                dark
                class="mb-2 subheading font-weight-bold"
                @click="openDialog"
              >
                <v-icon left>
                  mdi-plus
                </v-icon>
                Add New Client
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </template>

      <v-data-table
        :headers="headers"
        :items="clients"
        :search="search"
        sort-by="calories"
        class="elevation-1"
      >
        <v-progress-linear
          :active="fetchup"
          :indeterminate="fetchup"
          absolute
          bottom
          color="primary"
        />
        <template v-slot:item.actions="{ item }">
          <!--        subscription: null-->
          <v-icon
            v-if="item.subscription === null"
            right
            class="grey--text"
            x-small
          >
            mdi-checkbox-blank-circle
          </v-icon>
          <v-icon
            v-else
            right
            class="pulse green"
            x-small
          >
            mdi-checkbox-blank-circle
          </v-icon>
          <span class="mx-2" />
          <v-btn
            small
            text
            class="primary"
            @click="viewSubDetails(item)"
          >
            <v-icon
              small
              class="mr-2 white--text"
            >
              mdi-creation
            </v-icon>
            Subscription
          </v-btn>
          <v-btn
            small
            text
            class="grey--text"
            @click="editItem(item)"
          >
            <v-icon
              small
              class="mr-2"
            >
              mdi-pencil
            </v-icon>
            Edit
          </v-btn>
        </template>
        <template v-slot:no-data>
          <v-btn
            color="primary"
            @click="fetchClients"
          >
            Reset
          </v-btn>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
    /* eslint-disable */
    import { mapGetters } from 'vuex'
    import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
    import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

    setInteractionMode('eager')

    extend('digits', {
        ...digits,
        message: '{_field_} needs to be {length} digits. ({_value_})',
    })

    extend('required', {
        ...required,
        message: '{_field_} can not be empty',
    })

    extend('max', {
        ...max,
        message: '{_field_} may not be greater than {length} characters',
    })

    extend('regex', {
        ...regex,
        message: '{_field_} {_value_} does not match {regex}',
    })

    extend('email', {
        ...email,
        message: 'Email must be valid',
    })


  const axios = require('axios')
  export default {
    name: 'ManageClients',
    data: () => ({
      total: null,
      search: null,
      fetchup: false,
      menu: false,
      dialog: false,
      dialogDelete: false,
      subscribeDialog: false,
      sub: {
        package: { _id: '', name: '', price: '' },
        startDate: null,
        endDate: null,
        receiptNo: null,
        paymentMethod:null,
      },
      headers: [
        {
          text: 'First Name',
          align: 'start',
          sortable: false,
          value: 'firstName',
        },
        { text: 'Last Name', value: 'lastName' },
        { text: 'Email', value: 'email' },
        { text: 'Phone No.', value: 'phone' },
        { text: 'Address', value: 'address' },
        { text: 'Actions', value: 'actions', sortable: false, align: 'center' },
      ],
      clients: [],
      getPaymentMethods:[
        'Cash',
        'Credit / Debit Card',
        'Mobile Money',
        'Bank Transfer'
      ],
      editedIndex: -1,
      editedItem: {
        firstName: '',
        lastName: '',
        password: 'client1212',
        confirmPassword: 'client1212',
        phone: '',
        email: '',
        dob: '',
        address: '',
        emergencyContactName: '',
        emergencyContactPhone: '',
      },
      defaultItem: {
        firstName: '',
        lastName: '',
        phone: '',
        password: 'client1212',
        confirmPassword: 'client1212',
        email: '',
        dob: '',
        address: '',
        emergencyContactName: '',
        emergencyContactPhone: '',
      },
    }),

    computed: {
      ...mapGetters(['getPackages']),
      formTitle () {
        return this.editedIndex === -1 ? 'Register New Client' : 'Edit Client'
      },
    },

    watch: {
      menu (val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      },
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.fetchClients()
    },

    methods: {
      openDialog(){
        this.dialog = true
      },

      onSubscribe () {
        const self = this
        this.fetchup = true
        this.subscribeDialog = false
        const subDetails = { user: this.editedItem._id, package: this.sub.package._id,
          receiptNo: this.sub.receiptNo, paymentMethod:this.sub.paymentMethod }

        axios.post(this.apiSet.subscription, subDetails)
          .then(response => {
            self.fetchup = false
            self.$store.commit('setSnack', { t: 'Success. Client was successfully subscribed.', c: 'green darken-3' })
            self.fetchClients()
          }).catch(
            e => {
              self.fetchup = false
              this.err.push(e)
            },
          )
      },
      saveDate (date) {
        this.$refs.menu.save(date)
      },
      viewSubDetails (clientData) {
        alert('view Sub Dets');
        this.editedItem = clientData
        this.subscribeDialog = true
        this.$store.dispatch('fetchPackages')
      },
      fetchClients () {
        this.fetchup = true
        const self = this
        axios.get(this.apiSet.getClients)
          .then(response => {
            const obj = response.data.users
            self.clients = obj
            self.total = obj.length
            self.fetchup = false
            // self.$store.commit('setSnack', { t: 'Success. Data was successfully added.', c: 'green darken-3' })
          }).catch(
            e => {
              self.fetchup = false
              this.err.push(e)
            },
          )
      },
      onAddClient () {
          const self = this

        if (this.editedIndex > -1) {
          this.updateClient()
        } else {

            this.$refs.observer.validate().then((result) => {
            if (result) {
                this.loader = true
                const catData = this.editedItem

                axios.post(this.apiSet.clients, catData)
                    .then(function (response) {
                        self.close()
                        self.fetchClients()
                        self.fetchup = false
                        self.$store.commit('setSnack', { t: 'Success. Data was successfully added.', c: 'green darken-3' })
                    })
                    .catch(error => {
                        self.loader = false
                        self.err.push(error)
                    })
            }else{
                self.$store.commit('setSnack', { t: 'Please correct form errors.', c: 'red darken-3' })
            }

            })
    }//else Big

      },
      updateClient () {
        const self = this
        // this.$validator.validateAll().then((result) => {
        //   if (result) {
        this.loader = true
        const catData = this.editedItem

        axios.put(this.apiSet.users + '/' + catData._id + '/clients', catData)
          .then(function (response) {
            self.close()
            self.fetchClients()
            self.loader = false
            this.$store.commit('setSnack', { t: 'Success. Data was successfully added.', c: 'green darken-3' })
          })
          .catch(error => {
            self.loader = false
            self.err.push(error)
          })
        // } else {
        //   self.snack = true
        //   self.snackText = 'Error. Please correct form errors.'
        //   self.snackColor = 'red'
        //   return false
        // }
          // })
      },

      editItem (item) {
        this.editedIndex = this.clients.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.editedItem.emergencyContactName = item.emergencyContact.name
        this.editedItem.emergencyContactPhone = item.emergencyContact.phone
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.clients.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.clients.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
          this.$refs.observer.reset()
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      closeSubDialog () {
        this.subscribeDialog = false
        this.sub.package = { _id: '', name: '', price: '' }
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.clients[this.editedIndex], this.editedItem)
        } else {
          this.clients.push(this.editedItem)
        }
        this.close()
      },
    },
  }
</script>
<style scoped>
  .pulse {
    cursor: pointer;
    border-radius:50px;
    animation: pulse 1s infinite;
  }

  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(22, 160, 133, 0.4);
    }
    70% {
      -webkit-box-shadow: 0 0 0 10px rgba(22, 160, 133, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(22, 160, 133, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(22, 160, 133, 0.4);
      box-shadow: 0 0 0 0 rgba(22, 160, 133, 0.4);
    }
    70% {
      -moz-box-shadow: 0 0 0 10px rgba(22, 160, 133, 0);
      box-shadow: 0 0 0 10px rgba(22, 160, 133, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(22, 160, 133, 0);
      box-shadow: 0 0 0 0 rgba(22, 160, 133, 0);
    }
  }
</style>
